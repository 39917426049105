import React from 'react'
import PropTypes from 'prop-types'
import DivideLine from '../DivideLine/Loadable'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import KebabIcon from '../../asset/images/button/kebab-menu.svg'
import KebabActiveIcon from '../../asset/images/button/kebab-menu-active.svg'
import CopyIcon from '../../asset/images/icon-copy.svg'
import ViolationCaseStyled from './styledComponent'
import MESSAGE from './message'

class ViolationCaseCard extends React.PureComponent {
  state = {
    violationPopupOpen: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  openViolationPopupBtn() {
    this.setState({
      violationPopupOpen: true
    })
  }

  closeViolationPopupBtn() {
    this.setState({
      violationPopupOpen: false
    })
  }

  _deleteViolationById(violationId) {
    this.props.deleteViolationClick(violationId)
    this.closeViolationPopupBtn()
  }

  _toggleReportViolation(violationId, reported) {
    this.props.reportViolationById(violationId, reported, this.props.data)
    this.closeViolationPopupBtn()
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser.language]
    return msg[property]
  }

  getViolationMenuPopup() {
    const { data } = this.props
    const reportKebabText = data.reported ? this.getMessage('unreport_option') : this.getMessage('report_option')
    const deleteText = this.getMessage('delete_option')
    let output = null
    if (this.state.violationPopupOpen) {
      output = (
        <>
          <div className="menu-option-wrapper" onClick={() => this._toggleReportViolation(data.id, !data.reported)}>
            {reportKebabText}
          </div>
          {this.props.currentUser?.admin && (
            <div className="menu-option-wrapper" onClick={() => this._deleteViolationById(data.id)}>
              {deleteText}
            </div>
          )}
        </>
      )
    }
    return (
      <div className="option-wrapper text-style" onMouseOut={(e) => this.handleMouseOutOptions(e)} ref={(wp) => (this.wrapperRef = wp)}>
        {output}
      </div>
    )
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.closeViolationPopupBtn()
    }
  }

  handleMouseOutOptions(e) {
    const currentTarget = e.target
    const relatedTarget = e.relatedTarget
    if (currentTarget.parentNode !== relatedTarget.parentNode) {
      this.closeViolationPopupBtn()
    }
  }

  getKebabButton() {
    const { violationPopupOpen } = this.state
    const output = (
      <div className="violation-menu-wrapper">
        <img src={violationPopupOpen ? KebabActiveIcon : KebabIcon} alt="" onClick={() => this.openViolationPopupBtn()} />
      </div>
    )
    return output
  }

  _copyToClipboard = (value) => {
    const mockInput = document.createElement('input')
    mockInput.value = value
    mockInput.setAttribute('readonly', '')
    mockInput.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(mockInput)
    mockInput.select()
    document.execCommand('copy')
    document.body.removeChild(mockInput)
  }

  _renderViolationType = (data) => {
    const tooltipText = this.getMessage('copied_tooltip')
    const reportedText = this.getMessage('reported_text')
    return (
      <div className="content">
        <div className="value prevent-text-overflow">{data.violation_type}</div>
        <div className="case-id-wrapper prevent-text-overflow">{`${this.getMessage('case_id_subheader')} ${data.case_id}`}</div>
        <div className="icon-wrapper" onClick={() => this._copyToClipboard(data.case_id)} title={tooltipText}>
          <img src={CopyIcon} alt="" />
        </div>
        {data.reported && <span className="report-text">{reportedText}</span>}
      </div>
    )
  }

  _shouldRenderDetectionInfo = (color_range, color_value, violation_type) => {
    const { currentUser } = this.props
    return color_range && typeof color_value !== 'undefined' && violation_type !== 'Unauthorized' && (currentUser?.admin || currentUser?.manager)
  }

  _renderDetectionInfo = (data) => {
    let output = null
    const { min_value, max_value } = data.color_range
    const { color_value, color_saturation, color_lightness, allowed, color_supported } = data
    const allowedText = allowed ? this.getMessage('allowed_text') : this.getMessage('not_allowed_text')
    output = (
      <>
        <DivideLine className="detail-divide" />
        <div className="detail-row-container">
          <div className="content">
            <div className="title">{this.getMessage('violation_detection_info')}</div>
            <div className="color-wrapper">
              <div className="color-wrapper-row">
                <div className="color-wrapper-row-title">{this.getMessage('color_range_subtitle')}</div>
                <div className="color-wrapper-row-value">{`${min_value}-${max_value}`}</div>
              </div>
              <div className="color-wrapper-subrow">
                <div className="color-wrapper-subrow-title">{allowedText}</div>
              </div>
              <div className="color-wrapper-row">
                <div className="color-wrapper-row-title">{this.getMessage('detected_subtitle')}</div>
                <div className="color-wrapper-row-value flex align-items-center">
                  <div className="color-dot" style={{ background: `hsl(${color_value}, ${color_saturation}%, ${color_lightness}%)` }}>
                    &nbsp;
                  </div>
                  <div className="color-value">{color_value}</div>
                </div>
              </div>
              {!color_supported && (
                <div className="color-wrapper-subrow">
                  <div className="color-wrapper-subrow-title">{this.getMessage('unsupported_color_text')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
    return output
  }

  _renderDateAndTime = (violationDate, violationTime) => {
    const modViolationTime = violationTime.split('.')[0]
    return (
      <>
        <div className="value">{`${violationDate}`}</div>
        <div className="value">{`${modViolationTime}`}</div>
      </>
    )
  }

  getDetailBox() {
    const { data } = this.props
    const showDetectionInfo = this._shouldRenderDetectionInfo(data.color_range, data.color_value, data.violation_type)
    const dateTimeClassName = showDetectionInfo ? 'date-time-wrapper' : 'date-time-wrapper no-detection-info'
    return (
      <div className="detail-container">
        <div className="detail-header">
          {this.getMessage('detail_header')}
          {this.getKebabButton()}
        </div>
        {this.getViolationMenuPopup()}
        <div className="detail-content-container">
          <div className="detail-row-container">{this._renderViolationType(data)}</div>
          {showDetectionInfo && this._renderDetectionInfo(data)}
          {!showDetectionInfo && <DivideLine className="detail-divide" />}
          <div className={dateTimeClassName}>{this._renderDateAndTime(data.violation_date, data.violation_time)}</div>
        </div>
      </div>
    )
  }

  getViolationCaseDetail() {
    const { data } = this.props
    return (
      <>
        <div className="screenshot-camera-container">
          <img src={data.frame_url} alt="" />
        </div>
        <div className="violation-data-container">{this.getDetailBox()}</div>
      </>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getViolationCaseDetail()
    return content
  }

  render() {
    return <ViolationCaseStyled className="case-card">{this.getContent()}</ViolationCaseStyled>
  }
}

ViolationCaseCard.defaultProps = {
  currentUser: {
    firstname: '',
    lastname: '',
    email: '',
    language: 'EN'
  }
}

ViolationCaseCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    frame_url: PropTypes.string,
    violation_time: PropTypes.string,
    violation_date: PropTypes.string,
    violation_type: PropTypes.string,
    case_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color_value: PropTypes.number,
    color_range: PropTypes.shape({
      min_value: PropTypes.number,
      max_value: PropTypes.number
    }),
    color_supported: PropTypes.bool,
    color_saturation: PropTypes.number,
    color_lightness: PropTypes.number,
    allowed: PropTypes.bool,
    reported: PropTypes.bool
  }),
  isLoading: PropTypes.bool,
  currentUser: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    language: PropTypes.string
  }),
  deleteViolationClick: PropTypes.func,
  reportViolationById: PropTypes.func
}

export default ViolationCaseCard
