import styled from 'styled-components'

export default styled.div`
  display: flex;
  height: 295px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .screenshot-camera-container {
    background: ${(props) => props.theme.darkBackground};
    width: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .violation-data-container {
    display: flex;
    flex: 1;
    width: 210px;
    .detail-container {
      width: 100%;
      .detail-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        max-height: 40px;
        padding: 9px 5px 10px 40px;
        font-family: 'Prompt-Mediun', sans-serif;
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1f2d39;
        background: ${(props) => props.theme.cardTitle};

        .violation-menu-wrapper {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .detail-content-container {
        padding-left: 20px;
        padding-right: 10px;
        background: white;
        height: calc(100% - 40px);
        .detail-row-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 80px;
          .content {
            display: flex;
            flex-direction: column;
            padding: 16px 12px 0;
            position: relative;
            width: 100%;
            height: 100%;
            .title {
              font-family: 'Prompt', sans-serif;
              font-size: 10px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: 1;
              letter-spacing: normal;
              text-transform: uppercase;
              color: rgba(31, 45, 57, 0.5);
              margin-bottom: 10px;
            }
            .value {
              font-family: 'Prompt', sans-serif;
              font-size: 15px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: ${(props) => props.theme.darkBackground};
              max-width: 165px;
            }
            .case-id-wrapper {
              font-family: 'Prompt', sans-serif;
              font-size: 10px;
              max-width: 160px;
            }
            .icon-wrapper {
              width: 16px;
              height: 16px;
              position: absolute;
              right: 0px;
              top: 35px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .report-text {
              font-family: 'Prompt', sans-serif;
              font-weight: 500;
              font-size: 12px;
              color: ${(props) => props.theme.flagRedFont};
            }

            .color-wrapper {
              &-row {
                display: flex;
                justify-content: space-between;
                color: ${(props) => props.theme.darkBackground};
                font-size: 12px;
                &-title {
                  font-family: 'Prompt-Medium', sans-serif;
                  font-weight: 600;
                }
                &-value {
                  flex-wrap: wrap;
                  font-family: 'Prompt', sans-serif;
                  .color-dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid ${(props) => props.theme.greyDisabled};
                  }
                  .color-value {
                    margin-left: 4px;
                  }
                }
              }
              &-subrow {
                margin-top: 2px;
                margin-bottom: 6px;
                &-title {
                  font-family: 'Prompt-Medium', sans-serif;
                  font-size: 10px;
                }
                &:last-child {
                  color: ${(props) => props.theme.flagRedFont};
                }
              }
            }
          }
        }
        .date-time-wrapper {
          font-family: 'Prompt', sans-serif;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          justify-content: space-around;
          margin-top: 36px;
          &.no-detection-info {
            margin-top: 12px;
          }
        }
      }
      .option-wrapper {
        position: absolute;
        margin-left: 20px;
        background: white;
        width: 190px;
        border-radius: 5px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
        .menu-option-wrapper {
          display: flex;
          align-items: center;
          min-height: 44px;
          font-size: 12px;
          padding-left: 15px;
          &:hover {
            cursor: pointer;
            background: ${(props) => props.theme.cardTitle};
          }
        }
      }
    }
  }
`
