const MESSAGE = {
  EN: {
    detail_header: 'CASE SUMMARY',
    violation_detection_info: 'DETECTION INFO',
    case_id_subheader: 'Case ID:',
    delete_option: 'Delete',
    report_option: 'Report case',
    unreport_option: 'Unreport case',
    allowed_text: 'Allowed',
    not_allowed_text: 'Not allowed',
    copied_tooltip: 'Copy',
    reported_text: 'Case reported',
    color_range_subtitle: 'Color range',
    detected_subtitle: 'Detected',
    unsupported_color_text: 'This color is not supported'
  },
  TH: {
    detail_header: 'สรุปข้อมูลการฝ่าฝืน',
    violation_detection_info: 'ข้อมูลการตรวจจับ',
    case_id_subheader: 'ID กรณี:',
    delete_option: 'ลบ',
    report_option: 'รายงานการฝ่าฝืน',
    unreport_option: 'ยกเลิกรายงาน',
    allowed_text: 'อนุญาต',
    not_allowed_text: 'ไม่อนุญาต',
    copied_tooltip: 'คัดลอก',
    reported_text: 'การฝ่าฝืนที่ถูกรายงาน',
    color_range_subtitle: 'ช่วงสี',
    detected_subtitle: 'ที่ตรวจจับได้',
    unsupported_color_text: 'ระบบไม่รองรับสีนี้'
  }
}

export default MESSAGE
